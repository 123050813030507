.footer {
  bottom: 0;
  color: white;
  font-size: .5em;
  position: fixed;
  text-align: center;
  width: 100vw;

  &--copy-right {
    outline-style: none;
  }
}