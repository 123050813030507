.home-page {
  container-type: inline-size;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  text-align: center;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;

  &--logo {
    width: 60%;
  }
}

/* If the container is larger than 1400px */
@container (min-width: 800px) {
  .home-page {
    &--logo {
      width: 40%;
    }
  }
}

/* If the container is larger than 1400px */
@container (min-width: 1400px) {
  .home-page {
    &--logo {
      width: 35%;
    }
  }
}

/* If the container is larger than 1700px */
@container (min-width: 1700px) {
  .home-page {
    &--logo {
      width: 30%;
    }
  }
}

